import * as React from 'react';
import PropType from 'prop-types';

interface IOverlayProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children?: any;
}

const Overlay: React.FC<IOverlayProps> = ({ children }) => {
    const content = children ? <div className="overlay__content">{children}</div> : '';

    return <div className="overlay">{content}</div>;
};

Overlay.propTypes = {
    children: PropType.node,
};

Overlay.defaultProps = {
    children: undefined,
};

export { Overlay };
