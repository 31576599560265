import React from 'react';
import PropType from 'prop-types';
// import { retrieveBaseUrl } from '../Authentication/baseUrlStore';
import { retrieveAuthenticationUserName } from '../Authentication/authenticationStore';

const ScreenHeader: React.FC<IScreenHeaderProps> = ({ headerText }) => {
    // const uiBaseUrl = retrieveBaseUrl();
    const userName = retrieveAuthenticationUserName();
    return (
        <header id="top-nav-container" className="screen-header">
            <h1><span>{userName} is logged in</span></h1>
        </header>
    );
};

ScreenHeader.propTypes = {
    headerText: PropType.string,
};

ScreenHeader.defaultProps = {
    headerText: undefined,
};

export interface IScreenHeaderProps {
    headerText?: string;
}

export { ScreenHeader };
