import React, { ReactNode, useEffect, useState } from 'react';
import PropType from 'prop-types';
import { authenticate } from './authenticationService';
import { Unauthorised } from './Unauthorised';
import { ShowLoadingOverlay } from '../Indicators/ShowLoadingOverlay';

interface IAuthProps {
    children?: ReactNode;
}

enum AuthStatus {
    checking = 0,
    authenticated = 1,
    failed = 2,
}

const Authentication: React.FC<IAuthProps> = ({ children }: IAuthProps) => {
    const [authStatus, setAuthStatus] = useState(AuthStatus.checking);

    useEffect(() => {
        authenticate()
            .then(result => {
                if (result.authenticated) {
                    setAuthStatus(AuthStatus.authenticated);
                } else {
                    setAuthStatus(AuthStatus.failed);
                }
            })
            .catch(_error => {
                setAuthStatus(AuthStatus.failed);
            });
    }, []);

    if (authStatus === AuthStatus.failed) {
        return <Unauthorised />;
    }

    if (authStatus === AuthStatus.authenticated) {
        return <div id="app__container--auth">{children}</div>;
    }

    return <ShowLoadingOverlay status="Authentication in progress" spinnerColor="#3A1C46" />;
};

Authentication.propTypes = {
    children: PropType.node,
};

Authentication.defaultProps = {
    children: undefined,
};

export { Authentication };
