const authenticationResultKey = 'api-authentication-result';

export const storeAuthenticationResult = (result: string): void => {
    sessionStorage.setItem(authenticationResultKey, result);
};
const retrieveAuthenticationResult = (): IAuthenticationApiResult | null | undefined => {
    if (!sessionStorage.getItem(authenticationResultKey)) {
        return null;
    }
    const result: IAuthenticationApiResult = JSON.parse(sessionStorage.getItem(authenticationResultKey) || '');
    return result;
};
export const retrieveAuthenticationToken = (): string | null => {
    return retrieveAuthenticationResult()?.jwt || '';
};

export const retrieveAuthenticationUserId = (): number | null => {
    return retrieveAuthenticationResult()?.userId || 0;
};
export const retrieveAuthenticationUserName = (): string | null => {
    return retrieveAuthenticationResult()?.userName || '';
};

export const resetAuthenticationTokenStore = (): void => {
    sessionStorage.removeItem(authenticationResultKey);
};
export interface IAuthenticationApiResult {
    jwt: string;
    userName?: string;
    userId: number;
}
