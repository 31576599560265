const baseUrlKey = 'api-base-url';

export const storeBaseUrl = (url: string): void => {
    sessionStorage.setItem(baseUrlKey, url);
};

export const retrieveBaseUrl = (): string | null => {
    return sessionStorage.getItem(baseUrlKey);
};

export const resetBaseUrlStore = (): void => {
    sessionStorage.removeItem(baseUrlKey);
};
