import * as React from 'react';
import { isDevelopment } from './isDevelopment';

export const DevelopmentOnly: React.FC<IDevelopmentOnlyProps> = props => {
    return isDevelopment() ? props.children : null;
};

interface IDevelopmentOnlyProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children?: any;
}
