import * as React from 'react';
import PropType from 'prop-types';
import { Overlay } from './Overlay';
import { ShowLoading } from '.';

export interface IShowLoadingOverlayProps {
    spinnerColor?: string;
    status?: string;
}

const ShowLoadingOverlay: React.FC<IShowLoadingOverlayProps> = ({ spinnerColor, status }) => {
    return (
        <Overlay>
            <ShowLoading status={status} spinnerColor={spinnerColor} />
        </Overlay>
    );
};

ShowLoadingOverlay.propTypes = {
    status: PropType.string,
    spinnerColor: PropType.string,
};

ShowLoadingOverlay.defaultProps = {
    status: undefined,
    spinnerColor: undefined,
};

export { ShowLoadingOverlay };
