import React from 'react';

const Unauthorised: React.FC = () => {
    return (
        <div className="unauthorised__panel" id="unauthorised__container">
            <h1>Unauthorised</h1>
            <h4>Error code 401</h4>
            <p>You are not authorised to access account servicing</p>
            <a href="/" id="unauthorised__link--xplan">
                Return to Xplan
            </a>
        </div>
    );
};

export { Unauthorised };
