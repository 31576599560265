import * as React from 'react';
import ReactLoading from 'react-loading';
import PropType from 'prop-types';

export interface ILoadingProps {
    spinnerColor?: string;
    status?: string;
    type?: string;
}

const ShowLoading: React.FunctionComponent<ILoadingProps> = ({ status, spinnerColor }) => {
    return (
        <div className="show-loading-container" id="show-loading-container">
            <div className="show-loading-animation" id="show-loading-animation">
                <ReactLoading color={spinnerColor} type="cylon" />
            </div>

            {status && <p>{status}</p>}
        </div>
    );
};

ShowLoading.propTypes = {
    spinnerColor: PropType.string,
    status: PropType.string,
};

ShowLoading.defaultProps = {
    spinnerColor: '#ddd',
    status: undefined,
};

export { ShowLoading };
