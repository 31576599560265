import { getNonce, getApiUrl } from './xplanHandover';
import {
    retrieveAuthenticationToken,
    resetAuthenticationTokenStore,
    IAuthenticationApiResult,
    storeAuthenticationResult,
} from './authenticationStore';
import { storeBaseUrl, retrieveBaseUrl, resetBaseUrlStore } from './baseUrlStore';

export interface IAuthenticationResult {
    authenticated: boolean;
    authenticationToken?: string;
    failureReason?: string;
}

const authenticatedResult = (token: string): IAuthenticationResult => {
    return {
        authenticated: true,
        authenticationToken: token,
        failureReason: undefined,
    };
};

const unauthenticatedResult = (reason: string): IAuthenticationResult => {
    return {
        authenticated: false,
        authenticationToken: undefined,
        failureReason: reason,
    };
};

const authenticationDetailsStored = (apiResult: IAuthenticationApiResult): boolean => {
    storeAuthenticationResult(JSON.stringify(apiResult));
    const storedToken = retrieveAuthenticationToken();
    return storedToken === apiResult.jwt;
};

const baseUrlIsStored = (url: string): boolean => {
    storeBaseUrl(url);
    const storedUrl = retrieveBaseUrl();
    return storedUrl === url;
};

const apiAuthentication = async (nonce: string, apiUrl: string): Promise<IAuthenticationResult> => {
    const url = `${apiUrl}/api/authentication?token=${nonce}`;

    const result = fetch(url)
        .then(async response => {
            if (response.status === 200) {
                const jwtResult: IAuthenticationApiResult = await response.json();
                if (authenticationDetailsStored(jwtResult) && baseUrlIsStored(apiUrl)) {
                    return authenticatedResult(jwtResult.jwt);
                }
                return unauthenticatedResult('failed to store token for reuse');
            }
            return unauthenticatedResult(await response.text());
        })
        .catch(error => {
            return unauthenticatedResult(error);
        });

    return result;
};

export const authenticate = async (): Promise<IAuthenticationResult> => {
    resetAuthenticationTokenStore();
    resetBaseUrlStore();

    const nonce = getNonce();
    if (!nonce) {
        return unauthenticatedResult('Not initialised with required authentication token');
    }

    const apiUrl = getApiUrl();
    if (!apiUrl) {
        return unauthenticatedResult('Not initialised with required api url');
    }

    return apiAuthentication(nonce, apiUrl);
};
