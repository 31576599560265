import React from 'react';
import PropType from 'prop-types';

const ScreenFooter: React.FC<IScreenFooterProps> = ({ copyright, applicationVersion }) => {
    return (
        <div id="footer__container">
            <footer id="footer__footer" className="footer">
                <div className="container-fluid" id="footer__container-content">
                    <p>
                        <span id="footer__text--application-copyright">{copyright}</span>
                        &nbsp;
                        <span id="footer__text--application-version">{applicationVersion}</span>
                    </p>
                </div>
            </footer>
        </div>
    );
};

ScreenFooter.propTypes = {
    applicationVersion: PropType.string,
    copyright: PropType.string,
};

ScreenFooter.defaultProps = {
    applicationVersion: undefined,
    copyright: undefined,
};

export interface IScreenFooterProps {
    applicationVersion?: string;
    copyright?: string;
}

export { ScreenFooter };
