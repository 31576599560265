import { isDevelopment } from "../../Services/developmentMode";


interface IXplanMicroFrontEndWindowHandover {
    bffNonceToken?: string;
    bffURL?: string;
}

declare global {
    interface Window {
        primePlusDeployConfig: IXplanMicroFrontEndWindowHandover | undefined;
    }
}

const getNonceFromXplanConfig = (): string | undefined => {
    const { primePlusDeployConfig } = window;
    return primePlusDeployConfig ? primePlusDeployConfig.bffNonceToken : undefined;
};

const getNonceInDevelopment = (): string => {
    const localStorageOverride = localStorage.getItem('bffNonceToken');
    return localStorageOverride || 'xplan.testuser';
};

const getApiUrlFromXplanConfig = (): string | undefined => {
    const { primePlusDeployConfig } = window;
    return primePlusDeployConfig ? primePlusDeployConfig.bffURL : undefined;
};

const getApiUrlInDevelopment = (): string => {
    const localStorageOverride = localStorage.getItem('bffURL');
    return localStorageOverride || 'http://localhost:8091';
};

export const getNonce = (): string | undefined => {
    return isDevelopment() ? getNonceInDevelopment() : getNonceFromXplanConfig();
};

export const getApiUrl = (): string | undefined => {
    return isDevelopment() ? getApiUrlInDevelopment() : getApiUrlFromXplanConfig();
};

export const setNonce = (nonce: string | undefined): void => {
    window.primePlusDeployConfig = {
        ...window.primePlusDeployConfig,
        bffNonceToken: nonce,
    };
};

export const setApiUrl = (url: string | undefined): void => {
    window.primePlusDeployConfig = {
        ...window.primePlusDeployConfig,
        bffURL: url,
    };
};
