import React from 'react';


import { ScreenHeader } from '../ScreenHeader';
import { ScreenBody } from '../ScreenBody';

import { ScreenFooter } from '../ScreenFooter';
import { applicationVersion, applicationCopyright } from '../../Services/appMetadata/appMetadataService';



const Screen: React.FC = () => {


    return (
        <div id="app__container">

                <ScreenHeader headerText="Account Servicing" />
                <main id="app__container--main" className="app__container--body">
                    <ScreenBody />
                </main>
                <ScreenFooter applicationVersion={applicationVersion} copyright={applicationCopyright} />

        </div>
    );
};

export { Screen };
