import React from 'react';
import './App.css';
import { Authentication } from './components/Authentication';
import { Screen } from './components/Screen'
function App() {
  return (
  
          <Authentication>
            <Screen></Screen>
          </Authentication>
  
  );
}

export default App;
